import { useEffect } from 'react';
import Script from 'next/script';
import clsx from 'clsx';
import { onImageError } from '@/utils/sentry/onImageError';
import { Image } from '@/components/Image';
import { Warning } from '../Warning';
type Props = {
  className?: string;
  url?: string;
  image?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  imageAlt: string;
};
function getWistiaIdFromURL(url: string) {
  try {
    const paths = new URL(url).pathname.split('/');
    return paths[paths.indexOf('medias') + 1];
  } catch (e) {
    return null;
  }
}
export const WistiaImage = function WistiaImage({
  className,
  url,
  image,
  imageAlt
}: Props) {
  const id = url && getWistiaIdFromURL(url);
  useEffect(() => {
    if (!id) return;

    // @ts-expect-error _wq is not defined
    window._wq = window._wq || [];
    const config = {
      id,
      onReady: function () {}
    };

    // @ts-expect-error _wq is not defined
    window._wq.push(config);
    return () => {
      // @ts-expect-error _wq is not defined
      window._wq.push({
        revoke: config
      });
    };
  }, [id]);
  return <>
			<Script strategy="afterInteractive" src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} />
			<Script strategy="afterInteractive" src="https://fast.wistia.com/assets/external/E-v1.js" />
			<span className={clsx(className)}>
				{image ? <div className="popover">
						<div className="popover-content">
							<div className="wistia_responsive_padding relative pt-[56.25%]">
								<div className="wistia_responsive_wrapper absolute top-0 left-0 w-full h-full">
									<div className={`wistia_embed wistia_async_${id} popover=true popoverContent=link relative w-full h-full`}>
										<div className="wistia_swatch absolute top-0 left-0 w-full h-full transition-opacity duration-200">
											<Image alt={imageAlt} src={image.url} width={image.dimensions.width} height={image.dimensions.height} className="cursor-pointer" aria-hidden="true" onError={onImageError} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> : <Warning className={className}>Please select an image</Warning>}
			</span>
		</>;
};